import { gql, useQuery } from "@apollo/client";
import dayjs from "dayjs";
import { useAtom } from "jotai";
import { isEqual, isNumber } from "lodash";
import React from "react";

import { useRouter } from "@/App/Router";
import { GetValuationYearQuery_vurderingsejendom_Vurderingsejendom as Vurderingsejendom } from "@/graphql/__generated__/privateTypes";
import {
  activeYearAtom,
  metaAtom,
  PageTypeWithTimeStamp,
  valuationYearAtom,
  ValuationYearType,
} from "@/store";
import { usePrevious } from "@/utils/hooks/usePrevious";
import { MyPropertyPageTypes } from "@/view-models/MyPropertyPageTypes";
import { PageTypeLink } from "@/view-models/PageTypeLink";

import { getValuationYearLink } from "../routerUtils";
import useQueryParams from "./useQueryParams";

const VALUATION_YEAR = gql`
  query GetValuationYear($vurderingsejendom_id: Int! = -1) {
    vurderingsejendom(vurderingsejendom_id: $vurderingsejendom_id) {
      vurderingsejendom_id
      vurderingssager(vurderingsaar: []) {
        vurderingsaar
        vurderingsejendom_id
        deklarationer {
          brevdato
          datagrundlag {
            BFEnumre {
              BFEnummer
            }
          }
        }
        vurdering {
          brevdato
          datagrundlag {
            BFEnumre {
              BFEnummer
            }
          }
        }
      }
      historisk_vurderingssag {
        historisk_vurdering_klagefristbrev {
          brevdato
        }
        vurderingsaar
      }
      tilbagebetalingssag {
        vurderingsejendom_id
        tilbagebetaling {
          datagrundlag {
            brevdata {
              datapunkter {
                begrundelse
              }
            }
          }
          dokumentgruppe
          brevdato
        }
      }
    }
  }
`;

export function useValuationYear() {
  const [activeYear, setActiveYear] = useAtom(activeYearAtom);
  const [valuationYears, setValuationYears] = useAtom(valuationYearAtom);
  const { push } = useRouter();
  const queryParams = useQueryParams();
  const [meta] = useAtom(metaAtom);

  const {
    data,
    loading: queryLoading,
    error,
  } = useQuery(VALUATION_YEAR, {
    variables: {
      vurderingsejendom_id: queryParams.ejendomsid,
    },
    skip: !queryParams?.ejendomsid,
  });

  const mappedYears = React.useMemo(() => {
    const property = data?.vurderingsejendom as Vurderingsejendom;

    // get valuation years with judicial category if available
    const years: Array<ValuationYearType> =
      property?.vurderingssager
        ?.filter((sag) => sag && sag.vurderingsaar)
        ?.filter(
          (sag) =>
            sag?.vurdering ||
            (sag?.deklarationer && sag?.deklarationer.length > 0)
        )
        ?.map((sag) => {
          const pageTypes: Array<PageTypeWithTimeStamp> = [];

          if (sag?.deklarationer) {
            pageTypes.push({
              pageType: MyPropertyPageTypes.grundlag,
              timeStamp: sag?.deklarationer?.[0]?.brevdato,
            });
          }

          if (sag?.vurdering) {
            pageTypes.push({
              pageType: MyPropertyPageTypes.vurdering,
              timeStamp: sag?.vurdering?.brevdato,
            });
          }

          return {
            // disable this as it has already been filtered out
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            year: sag!.vurderingsaar,
            // propertyCategory:
            //   sag?.deklarationer?.[0]?.datagrundlag?.ejendomsoplysninger
            //     ?.juridisk_kategori?.kategori_id ?? null,
            pageTypes: pageTypes,
          } as ValuationYearType;
        }) ?? [];

    if (isNumber(property?.historisk_vurderingssag?.vurderingsaar)) {
      const historicYear = property?.historisk_vurderingssag?.vurderingsaar;

      const yearIndex = years.findIndex(
        (v) => v.year === property?.historisk_vurderingssag?.vurderingsaar
      );

      const pageTypeWithTimeStamp: PageTypeWithTimeStamp = {
        pageType: MyPropertyPageTypes.historiskVurderingKlagefrist,
        timeStamp:
          property?.historisk_vurderingssag?.historisk_vurdering_klagefristbrev
            ?.brevdato,
      };

      if (yearIndex === -1) {
        //Modulus operator to determine if historicYear is odd or even
        years.push({
          year: historicYear,
          propertyCategory: Boolean(historicYear % 2) ? 2100 : 1100,
          pageTypes: [pageTypeWithTimeStamp],
        });
      } else {
        years[yearIndex].pageTypes.push(pageTypeWithTimeStamp);
      }
    }

    if (property?.tilbagebetalingssag) {
      const yearIndex = years.findIndex((v) => v.year === 2020);

      const pageTypeWithTimeStamp: PageTypeWithTimeStamp = {
        pageType: MyPropertyPageTypes.kompensation,
        timeStamp:
          property?.tilbagebetalingssag?.tilbagebetaling?.[0]?.brevdato,
      };

      if (yearIndex === -1) {
        years.push({
          year: 2020,
          propertyCategory: 1100,
          pageTypes: [pageTypeWithTimeStamp],
        });
      } else {
        years[yearIndex].pageTypes.push(pageTypeWithTimeStamp);
      }
    }

    const sortedYears = years
      .filter(
        (year) =>
          meta?.valuationYears &&
          meta.valuationYears.findIndex((val) => val.year === year.year) !== -1
      )
      .sort((a, b) => b.year - a.year);

    // sort high to low
    return sortedYears;
  }, [data?.vurderingsejendom, meta.valuationYears]);

  const loading = queryLoading && !!mappedYears;
  const previousPropertyId = usePrevious(queryParams.ejendomsid);
  const previousMappedYears = usePrevious(mappedYears);
  const previousActiveYear = usePrevious(activeYear);

  const hasChanged =
    previousPropertyId !== queryParams?.ejendomsid ||
    !isEqual(previousMappedYears, mappedYears) ||
    !isEqual(previousActiveYear, activeYear);

  React.useEffect(() => {
    if (
      queryParams?.ejendomsid &&
      mappedYears &&
      mappedYears.length &&
      hasChanged &&
      activeYear[queryParams.ejendomsid]?.setBy !== "User"
    ) {
      //Use latest year if the year in the query param is not mapped
      let valuationYear = mappedYears[0];

      const getValuationYearFromMappedYears = () => {
        if (!queryParams.vurderingsaar) {
          return valuationYear;
        }

        const currentYear = mappedYears.find(
          (v) => v.year === Number(queryParams.vurderingsaar)
        );

        return currentYear ?? valuationYear;
      };

      valuationYear = getValuationYearFromMappedYears();

      setValuationYears(mappedYears);

      setActiveYear({
        [queryParams.ejendomsid as number]: {
          setBy: "System",
          year: valuationYear.year,
        },
      });
    } else if (
      !queryParams?.ejendomsid &&
      previousPropertyId &&
      previousActiveYear[previousPropertyId]
    ) {
      setActiveYear({
        [previousPropertyId as number]: {
          setBy: "System",
          year: previousActiveYear[previousPropertyId].year,
        },
      });
    }
  }, [
    queryParams,
    activeYear,
    mappedYears,
    setActiveYear,
    hasChanged,
    setValuationYears,
    previousPropertyId,
    previousActiveYear,
  ]);

  const getNewLocation = React.useCallback(
    (currentYear, newYear) => {
      const valuationYearLinks = getValuationYearLink(
        meta?.valuationYears,
        newYear
      );

      const findPageType = (pageType: MyPropertyPageTypes) => {
        const valuationYearKeys = Object.keys(
          valuationYearLinks.propertyCategories?.[0]
        );

        for (let index = 0; index < valuationYearKeys.length; index++) {
          const categoryLinks = valuationYearLinks.propertyCategories[0][
            valuationYearKeys[index]
          ] as PageTypeLink;

          if (categoryLinks.pageType === pageType) {
            return categoryLinks.href;
          }
        }

        return null;
      };

      const pageTypesForYear =
        mappedYears.find((year) => year.year === newYear)?.pageTypes ?? [];

      const dateSortedPageTypes = pageTypesForYear.sort((a, b) =>
        dayjs(a?.timeStamp).isAfter(dayjs(b?.timeStamp)) ? -1 : 1
      );

      const matchingPageType = dateSortedPageTypes?.find(
        (page) => page.pageType === meta.pageType
      );

      if (matchingPageType) {
        const matchingLink = findPageType(matchingPageType.pageType);

        if (matchingLink) return matchingLink;
      }

      for (let index = 0; index < dateSortedPageTypes.length; index++) {
        const currentPageType = dateSortedPageTypes[index].pageType;

        const matchingLink = findPageType(currentPageType);

        if (matchingLink) {
          return matchingLink;
        }
      }

      return valuationYearLinks?.propertyCategories?.[0]?.declarationLink?.href;
    },
    [mappedYears, meta.pageType, meta?.valuationYears]
  );

  const ChangeActiveYear = React.useCallback(
    (year: number) => {
      if (!queryParams.ejendomsid) return;

      const newLocation = getNewLocation(
        activeYear[queryParams.ejendomsid].year,
        year
      );

      if (queryParams.vurderingsaar && newLocation) {
        push(
          `${newLocation}?` +
            new URLSearchParams({
              ejendomsid: queryParams.ejendomsid.toString(),
              vurderingsaar: year.toString(),
            })
        );
      }

      setActiveYear({
        [queryParams.ejendomsid as number]: {
          setBy: "User",
          year: year,
        },
      });
    },
    [
      activeYear,
      getNewLocation,
      push,
      queryParams.ejendomsid,
      queryParams.vurderingsaar,
      setActiveYear,
    ]
  );

  return {
    loading,
    error,
    ChangeActiveYear,
    activeYear: activeYear[queryParams?.ejendomsid]?.year,
    valuationYears: valuationYears ?? [],
  };
}
